var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tutors,"item-key":"id"},on:{"click:row":_vm.selectRow},scopedSlots:_vm._u([(Object.keys(_vm.selected).length > 0)?{key:"top",fn:function(){return [_c('dashboard-tuteur-card-tutors-datatable-selected-card',{attrs:{"student":_vm.selected,"meetings":_vm.getMeetingsForStudent(_vm.selected.id)},on:{"newEvent":_vm.newEvent}})]},proxy:true}:null,{key:"item.nom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.prenom)+" "+_vm._s(item.nom)+" ")]}},(_vm.formations.length > 0)?{key:"item.formation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormationName(item.formation))+" ")]}}:null,(_vm.formations.length > 0)?{key:"item.hvisio",fn:function(ref){
var item = ref.item;
return [(_vm.getFormationVisio(item.formation))?_c('div',[_vm._v(" "+_vm._s(_vm.getFormationVisio(item.formation))+" heures ")]):_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({name:'formation-edit', params: {id: item.formation} })}}},[_vm._v(" Pas d'heure de visio configuré ")])])]}}:null,{key:"item.nextVisio",fn:function(ref){
var item = ref.item;
return [(_vm.nextVisio(item.id))?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(new Date(_vm.nextVisio(item.id).start),'dd MMMM à HH:mm'))+" ")]):_c('div',[_vm._v(" Pas de visio prévu ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }