<template>
  <div
    v-if="student"
  >
    <v-row>
      <v-col
        cols="4"
        class="ml-3"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ student.nom }} {{ student.prenom }}</v-list-item-title>
            <v-list-item-subtitle>{{ student.formation.nom }}</v-list-item-subtitle>
            <v-list-item-subtitle>En formation du {{ new Date(student.debutFormation) | date('dd MMMM') }} au {{ new Date(student.finFormation) | date('dd MMMM') }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn
              icon
              color="primary"
              @click="$router.push({name: 'student-single', params:{id: student.id}})"
            >
              <v-icon>{{ icons.mdiAccount }}</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ student.tel }}</v-list-item-title>
            <v-list-item-subtitle>Mobile</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-icon>
            <v-btn
              icon
              color="primary"
              :href="`tel:${student.tel}`"
            >
              <v-icon>{{ icons.mdiPhone }}</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ student.email }}</v-list-item-title>
            <v-list-item-subtitle>E-mail</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-icon>
            <v-btn
              icon
              color="primary"
              @click="newEmailDialog = true"
            >
              <v-icon>{{ icons.mdiAt }}</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-textarea
          v-model="note"
          class="mt-3"
          label="note"
          outlined
        >
        </v-textarea>
        <v-btn
          v-show="noteChanged"
          block
          color="primary"
          @click="updateNote"
        >
          Mettre à jour
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="6"
        class="d-flex align-center flex-column justify-space-around"
      >
        <v-spacer></v-spacer>
        <student-meeting-time-table
          v-if="meetings.length > 0"
          :student="student"
          :meetings="meetings"
        >
        </student-meeting-time-table>
        <h3 v-else>
          Pas encore de visio avec cet apprenant
        </h3>
        <v-btn
          color="primary"
          outlined
          @click="newMeetingDialog = true"
        >
          Nouveau Visio
        </v-btn>
        <v-spacer>
        </v-spacer>
      </v-col>
    </v-row>
    <new-email-dialog
      :dialog="newEmailDialog"
      :student="student"
      @closeDialog="newEmailDialog = false"
    >
    </new-email-dialog>
    <new-meeting-dialog
      :meeting-dialog="newMeetingDialog"
      :student="student"
      :tuteur="$store.state.user"
      @closeDialog="newMeetingDialog = false"
      @meetingAdded="newEvent"
    >
    </new-meeting-dialog>
  </div>
</template>

<script>
import { mdiPhone, mdiAt, mdiAccount } from '@mdi/js'
import StudentMeetingTimeTable from '@/components/StudentMeetingTimeTable.vue'
import NewEmailDialog from '@/components/NewEmailDialog.vue'
import NewMeetingDialog from '@/components/NewMeetingDialog.vue'

export default {
  components: {
    StudentMeetingTimeTable,
    NewEmailDialog,
    NewMeetingDialog,
  },
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    meetings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newMeetingDialog: false,
      newEmailDialog: false,
      note: null,
      icons: {
        mdiPhone,
        mdiAt,
        mdiAccount,
      },
    }
  },
  computed: {
    noteChanged() {
      return this.note !== this.student.tutorNote
    },
  },
  watch: {
    student(val) {
      this.note = val.tutorNote
    },
  },
  mounted() {
    this.note = this.student.tutorNote
  },
  methods: {
    updateNote() {
      const backspaceNote = this.note.replace(/\r?\n/g, '<br />')

      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.student.id}`, {
        tutorNote: backspaceNote,
      })
        .then(res => {
          const noteUpdate = {
            id: res.data.id,
            tutorNote: res.data.tutorNote,
          }
          this.$store.dispatch('tutorNoteUpdate', noteUpdate)
        })
        .catch(err => console.log(err))
        .finally()
    },
    newEvent(payload) {
      this.$emit('newEvent', payload)
    },
  },

}
</script>

<style>

</style>
